<template>
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <div class="mt-0">
        <div class="checkout-items order-last order-lg-first">
          <b-card>
           
              <h5 class="card-title"> {{tournament.name}}
                <b-badge :class="'color-' + (tournament.type == 0 ? 'american' : 'canarian')">{{ tournament.type == 0 ? 'Americana' : 'Canaria' }} </b-badge>
              </h5>             
              <h4 class="">Fecha y hora: {{ tournament.tournament_date ? formatDate(tournament.tournament_date) : ''}} {{tournament.tournament_hour ? formattedTime(tournament.tournament_hour) : ''}} </h4>
              <h4 class="">Nivel: {{ tournament.level }}</h4>
              <h4 class="">Nº pistas: {{ tournament.number_courts }}</h4>
              <h4 class="">Precio: {{ tournament.price }}</h4>
              <h4 class="">Premio: {{ tournament.prize }}</h4>

              <!-- <b-card-text class="mb-25">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                <strong> Confirmado: </strong>{{ tournament.players[0].pivot.assistance_confirmation == 0 ? 'No' : 'Sí' }}                   
              </b-card-text> -->
              <!-- <b-button variant="success" @click="confirmParticipation(item.id)" v-if="item.players[0].pivot.assistance_confirmation == 0 && confirmed_tour !== item.id" block>Confirmar participación</b-button> -->
    
              <b-card-text>
                <div v-for="match in tournament.matches">           
                  <div v-if="match.couple1 && match.couple2">
                  <div v-if="(match.couple1.player1_id == user.player.id || match.couple1.player2_id == user.player.id) || (match.couple2.player1_id == user.player.id || match.couple2.player2_id == user.player.id)">
                    <p class="h4 mt-2"><strong>Partido  {{match.number_round == 0 ? '1ª Ronda' : (match.number_round == 1 ? '2ª Ronda' : (match.number_round == 2 ? '3ª Ronda' : '4ª Ronda'))}}</strong></p>                  
                    <p>{{ match.court.name + ' ' + match.court.number }}</p>
                    <p class="text-center"> {{ match.couple1 ? match.couple1.player1.full_name + ' - ' + match.couple1.player2.full_name : ''
                      }}
                    </p>
                    <p class="text-center">
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="green" stroke-width="2" fill="none"
                        stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </p>
                    <p class="text-center"> {{ (match.couple2 ? match.couple2.player1.full_name : '') + ' - ' + (match.couple2 ? match.couple2.player2.full_name : '') }}

                    </p>
                    <div class="text-center">
                      <button class="btn btn-success mr-2" v-if="!match.result" @click.prevent="openInsertResultModal(match.id, match.couple1,match.couple2)">Introducir resultado</button>
                      <p v-else class="bg-success text-white p-1">                        
                        ESTE PARTIDO YA TIENE UNA PAREJA GANADORA</p>
                   
                      <!-- <button class="btn btn-danger" v-if="!match.result" @click="inserResultLose(match.id)">Perdido</button> -->
                    </div>

                  </div>
                  </div>

                </div>

                <p v-if="tournament.matches && tournament.matches.length == 0" class="p-1 text-danger">AÚN NO HAY PARTIDOS PARA ESTE TORNEO</p>
              </b-card-text>
          </b-card>
        </div>
      </div>
    </b-overlay>

    <b-modal id="result-modal" title="Introduce resultados del partido" class="custom-modal" no-close-on-backdrop modal-class="margin-top">
 
      <form ref="formRegister">        
        <b-row>
          <b-col xs="5">
            <input type="number" name="result1" v-model="result1" placeholder="Primera pareja" class="form-control mt-2">
          </b-col>     
          <span class="mt-2"><strong>-</strong></span>
          <b-col xs="5">           
            <input type="number" name="result2" v-model="result2" placeholder="Segunda pareja" class="form-control mt-2">
          </b-col>
        </b-row>
    
      </form>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="5">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="insertResultMatch">Enviar resultados</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>   

  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import vSelect from 'vue-select'
import moment from 'moment';
import { BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BListGroup, BCardText, BFormFile, BListGroupItem, BBadge, BOverlay, BCardBody, BLink, BButton, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable } from "bootstrap-vue";
export default {
  components: { vSelect, BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BCardText, BListGroup, BFormFile, BListGroupItem, BBadge, BCardBody, BLink, BButton, BOverlay, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable },
  data() {
    return {
      show: true,
      tournament_id: this.$route.params.tournament,
      tournament: { players: {} },
      match_id: null,   
      couple1: null, 
      couple2: null, 
      result1: null,
      result2: null,
      winners : '',
      selected_player: null,     
    }
  },
  created() {
    this.$http.get("/api/getTournament?id=" + this.tournament_id).then((response) => {
      this.tournament = response.data;
      this.show = false    
    })
   },

  methods: {
    getOptionKey(option) {
      return option ? option.id : null;
    },

    openInsertResultModal(match, couple1, couple2) {
      this.match_id = match;
      this.couple1 = couple1;
      this.couple2 = couple2;
      this.$bvModal.show('result-modal');
    
    },   
    
    insertResultMatch() {
      this.$http.post("/api/insertResultMatch",{'id': this.match_id,'player_id': this.user.player.id, 'type': 'winner', 'result1': this.result1, 'result2': this.result2}).then((response) => {
        this.show = false;
        this.winners = response.data;
        this.$http.get("/api/getTournament?id=" + this.tournament_id).then((response) => {
          this.tournament = response.data;
          this.show = false;
          this.result1 = null;
          this.result2 = null;
        })
        this.$bvModal.hide('result-modal');
        // window.location.reload();
      });
      this.$bvToast.toast('Resultados introducidos correctamente', { variant: 'success', noCloseButton: true });   
    },

    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD-MM-YYYY');
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.color-american {
  background-color: rgb(40, 218, 17);
}

.color-canarian {
  background-color: rgb(17, 144, 218);
}

.margin-top{
  margin-top: 8%;
}
#result-modal {
  margin-top: 40px;
}

</style>
